import React, { useContext } from "react"
import { graphql } from "gatsby"

import { useI18next, I18nextContext } from "gatsby-plugin-react-i18next"

import { MyContext } from "../context/my-context"
import { compressToEncodedURIComponent, decompressFromEncodedURIComponent } from "lz-string"

import { baseUrl, printserviceUrl } from "../settings"

import { getNewCustomLsId } from "../util/data"

import Layout from "../parts/layout"
import Row from "../parts/row"
import Seo from "../parts/seo"
import HeadingUnderline from "../parts/headingUnderline"
import CustomLs from "../parts/customLs"

import Edit from "../images/inline/icon-edit.svg"
import Print from "../images/inline/icon-print.svg"
import Pdf from "../images/inline/icon-pdf.svg"
import Share from "../images/inline/icon-share.svg"
import { getLangUrlPrefix } from "../util/index"


const SharePage = ({ location, pageContext }) => {
  const { t, navigate } = useI18next()
  const i18n = useContext(I18nextContext)

  const { state, dispatch } = useContext(MyContext)
  const shared = decompressFromEncodedURIComponent(location.pathname.split("/").slice(-1)[0])
  const data = JSON.parse(shared)


  const getShareUrl = (langPrefix = "en") => {
    const c = compressToEncodedURIComponent(JSON.stringify(data))
    let url = new URL(baseUrl)
    url.pathname = [getLangUrlPrefix(langPrefix), "share", c].filter(e => e).join("/")
    return url.href
  }

  const handleShareClick = () => {
    const url = getShareUrl()
    navigator.clipboard.writeText(url)
    alert(t("SHARE-COPIED_TO_CLIPBOARD"))
  }

  const handleEditClick = () => {
    const newCustomLsId = getNewCustomLsId(state.ls)

    navigate(`/build-your-own/edit`, {
      state: {
        hiddenid: newCustomLsId,
        initialData: {
          ...data,
          id: newCustomLsId
        }
      }
    })

  }

  const handlePdfClick = () => {
    if(printserviceUrl) {
      const prefix = i18n.language
      window.open(`${printserviceUrl}/generate?url=${getShareUrl(prefix)}`, '_blank');
    } else {
      dispatch.dialog({
        title: t("PDF-DIALOG_TITLE"),
        body: <>
          <p>{ t("PDF-DIALOG_BODY") }</p>
        </>,
        buttons: [
          <button key={"copy"} className="button">
            <Print />
            { t("BUTTONS-PRINT") }
          </button>
        ]
      })
    }
  }


  return (
    <Layout>
      <Seo title={ data?.title } />

      <Row classes={["no-print"]}>
        <HeadingUnderline title="Shared with you" classes={["center"]} />
        <p className="introduction">This Learning Scenario was shared with you. You can print it to try it out directly. Or you can edit it for further elaboration. Use the share button to share the edited version with your colleagues.</p>

        <div className="ctas center">
          <button className="button" onClick={ () => handleEditClick() }>
            <Edit /> edit
          </button>
          <button className="button" onClick={ () => { handleShareClick() } } >
            <Share /> { t("BUTTONS-SHARE") }
          </button>
          <button className="button" onClick={ () => { window.print(); return false; }}>
            <Print /> { t("BUTTONS-PRINT") }
          </button>
          <button className="button" onClick={ () => { handlePdfClick() } } >
            <Pdf /> { t("BUTTONS-PDF") }
          </button>
        </div>
      </Row>

      { data && <CustomLs data={data} terms={pageContext.terms} /> }

    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {in: [$language, "pt", "en"]}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default SharePage
